.leaflet-container {
  width: 100%;
  height: 100%;
  z-index: 0;
  border-radius: 10px;
  position: relative;
}
.leaflet-top.leaflet-right .leaflet-control-zoom {
  background-color: transparent;
  outline: none;
  border: none;
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}
