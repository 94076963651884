@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 0px;
}
/*
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
.custom-scrollbar::-webkit-scrollbar {
  background-color: aqua;
  height: 20px;
  border-radius: 10px;
} */
.audiowide-regular {
  font-family: "Audiowide", sans-serif;
  font-weight: 500;
  font-style: normal;
}
