/* Login.css */
input[type="checkbox"]::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-image: url('../../../public/assets/images/checkbox.png'); /* Replace with your checkmark image */
    background-size: contain;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
  
  input[type="checkbox"]:checked::after {
    opacity: 1; /* Show the checkmark image when checked */
  }
  