abbr:where([title]) {
  -webkit-text-decoration: none !important;
  text-decoration: none !important;
}

.react-calendar {
  border: none;
  font-family: inherit;
}

.react-calendar button {
  color: #adadad;
}
.react-calendar__tile--now {
  background: none;
}
.react-calendar__month-view__weekdays {
  color: #003b33;
  text-transform: none !important;
  font-size: 14px !important;
  font-style: none !important;
  text-decoration: none !important;
  font-weight: 500;
  border: none !important;
}

.react-calendar__tile:active,
.react-calendar__tile:focus {
  background: #008480 !important;
  color: white !important;
}

.react-calendar__tile:not(.react-calendar__tile--active):not(:focus):hover {
  background: #e6f6f4 !important;
  color: #003b33 !important;
}

.react-calendar__tile--in-range {
  background: #e6f6f4 !important;
  color: #003b33 !important;
}

.react-calendar__tile--active {
  background: #008480 !important;
  color: #fff !important;
}

.react-calendar__tile abbr {
  font-weight: 500;
  font-size: 12px !important;
}

.react-calendar__navigation button {
  font-size: 18px;
  font-weight: 700;
  color: #003b33;
}

.react-calendar__tile {
  display: flex;
  box-sizing: content-box;
  align-items: center;
  justify-content: center;
  padding: 6px 0px;
  width: 25px;
  height: 24px;
}

.react-calendar__month-view__days {
  gap: 0px;
}

.react-calendar__tile--in-range {
  background: #e6f6f4 !important;
  color: #003b33 !important;
}

.react-calendar__tile abbr {
  font-weight: 500;
  font-size: 12px !important;
}

.react-calendar__tile:active,
.react-calendar__tile:focus {
  background: #008480 !important;
  color: white !important;
}
